<template>
  <div>
    <el-dialog
        v-model="rateEditModalVisible"
        :title="`Изменение тарифа  |  ${editingRate.name}`"
        class="modal-95-70-60"
        @close="visiblePanel = 'basicSettings'"
    >
      <el-tabs v-model="visiblePanel">
        <el-tab-pane label="Настройки назначения" name="basicSettings">
          <el-row style="margin-bottom: 15px">
            <el-col :span="24">
              <el-form v-model="editingRate.basicSettings" :label-position="'top'" size="small">
                <el-form-item label="Приоритет">
                  <el-input v-model="editingRate.basicSettings.priority"></el-input>
                </el-form-item>
                <el-row :gutter="40">
                  <el-col :xs="24" :md="12">
                    <el-form-item label="Период назначения от">
                      <el-date-picker
                          v-model="editingRate.basicSettings.assignFrom"
                          type="date"
                          format="DD.MM.YYYY"
                          style="width:100%"
                          clearable
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="12">
                    <el-form-item label="Период назначения до">
                      <el-date-picker
                          v-model="editingRate.basicSettings.assignTo"
                          type="date"
                          format="DD.MM.YYYY"
                          style="width:100%"
                          clearable
                      >
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-col>
          </el-row>
          <el-button type="success" @click="saveRateBasicSettings" size="small" :loading="savingRate">Сохранить
          </el-button>
        </el-tab-pane>
        <el-tab-pane label="Настройки имён" name="namesSettings"
                     v-if="editingRate.rateType === 0 && [2,3,7, 8].includes(editingRate.rateSubtype)">
          <el-row>
            <el-col :span="24">
              <h4 class="report-parameters-title">Активные имена</h4>
              <el-tag size="large" v-for="name in editingRate.namesSettings.allNames" style="margin-right: 10px">
                {{ name.name }}
              </el-tag>
              <el-divider class="blue-divider"/>
              <h4 class="report-parameters-title">Выполнить действие</h4>
              <el-form v-model="editingRate.basicSettings" :label-position="'top'" size="small">
                <el-form-item label="Подпись">
                  <el-select v-model="editingRate.namesSettings.actionNameId" class="full-width">
                    <el-option
                        v-for="n in senders.filter(s => !s.forCalls).sort((n1, n2) => n1.name.localeCompare(n2.name))"
                        :label="n.name" :value="n._id"/>
                  </el-select>
                </el-form-item>
                <el-form-item label="Действие">
                  <el-select v-model="editingRate.namesSettings.actionType" class="full-width">
                    <el-option label="Моментальное назначение" value="now_add"/>
                    <el-option label="Моментальное удаление" value="now_remove"/>
                    <el-option label="Отложенное назначение" value="later_add"/>
                    <el-option label="Отложенное удаление" value="later_remove"/>
                  </el-select>
                </el-form-item>
                <el-form-item label="Немедленное списание" v-show="editingRate.namesSettings.actionType === 'now_add'">
                  <input type="checkbox" v-model="editingRate.namesSettings.withdrawNow" />
                </el-form-item>
                <el-form-item label="Дата" v-show="editingRate.namesSettings.actionType.startsWith('later')">
                  <el-date-picker
                      v-model="editingRate.namesSettings.actionDate"
                      type="date"
                      format="DD.MM.YYYY"
                      style="width:100%"
                      clearable
                  >
                  </el-date-picker>
                </el-form-item>
                <el-button type="success" @click="createNameAction" :loading="nameActionCreating"
                           style="margin-top: 15px">Выполнить
                </el-button>
              </el-form>
              <el-divider class="blue-divider"/>
              <el-table :data="editingRate.namesSettings.allActions">
                <el-table-column label="Имя" prop="name"/>
                <el-table-column label="Действие"
                                 :formatter="row => row.type === 'later_add' ? 'Отложенное назначение' : 'Отложенное удаление'"/>
                <el-table-column label="Дата" prop="date"/>
                <el-table-column>
                  <template #default="scope">
                    <remove-button
                        v-if="nameActionInFuture(scope.row)"
                        @confirm="removePostponedNameAction(scope.row)" :table-button="true"
                        :loading="removingPostponedAction">Удалить действие
                    </remove-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Настройки конверсии текста" name="conversionSettings"
                     v-if="editingRate.rateType === 0 && editingRate.rateSubtype === 8">
          <el-form v-model="editingRate.conversionSettings" :label-position="'top'" size="small">
            <el-row :gutter="20" style="margin-bottom: 15px">

              <el-col :xs="24" :md="12">
                <el-form-item label="Оператор" >
                  <el-select v-model="editingRate.conversionSettings.operatorGroup" class="full-width"
                             :filterable="true">
                    <el-option-group label="Группы">
                      <el-option
                          v-for="opg in $store.getters.operatorsGroupsRus"
                          :value="opg._id" :label="opg.title"
                      />
                    </el-option-group>
                    <el-option-group label="Операторы">
                      <el-option
                          v-for="op in $store.getters.operatorsRus"
                          :value="op._id" :label="op.OrgName"
                      />
                    </el-option-group>
                  </el-select>
                </el-form-item>
                <el-form-item label="Подпись">
                  <el-input v-model="editingRate.conversionSettings.sender"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="12">
                <el-form-item label="Цена">
                  <el-input v-model="editingRate.conversionSettings.price"></el-input>
                </el-form-item>
                <el-form-item label="Действие">
                  <el-select v-model="editingRate.conversionSettings.action" class="full-width">
                    <el-option label="Отбивать" value="reject"/>
                    <el-option label="Не конвертировать" value="do_not_convert"/>
                  </el-select>
                </el-form-item>

              </el-col>
            </el-row>
            <el-button type="success" @click="addConversionSettingRecord" size="small" :loading="savingRate">
              Сохранить
            </el-button>
          </el-form>
          <el-divider class="blue-divider"/>
          <el-table :data="editingRate.allConversionSettings">
            <el-table-column label="Оператор" prop="operatorGroupName">
              <template #default="scope">
                <span v-if="scope.$index !== this.editingConversionRowIndex">{{ scope.row.operatorGroupName }}</span>
                <el-select
                    v-else
                    v-model="editingConversionRow.operatorGroup" class="full-width" :filterable="true" size="small">
                  <el-option-group label="Группы">
                    <el-option
                        v-for="opg in $store.getters.operatorsGroupsRus"
                        :value="opg._id" :label="opg.title"
                    />
                  </el-option-group>
                  <el-option-group label="Операторы">
                    <el-option
                        v-for="op in $store.getters.operatorsRus"
                        :value="op._id" :label="op.OrgName"
                    />
                  </el-option-group>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Подпись" prop="sender">
              <template #default="scope">
                <span v-if="scope.$index !== this.editingConversionRowIndex">{{ scope.row.sender }}</span>
                <el-input v-model="editingConversionRow.sender" v-else size="small"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Цена" prop="price">
              <template #default="scope">
                <span v-if="scope.$index !== this.editingConversionRowIndex">{{ scope.row.price }}</span>
                <el-input v-model="editingConversionRow.price" v-else size="small"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Действие">
              <template #default="scope">
                <span v-if="scope.$index !== this.editingConversionRowIndex">
                  {{ scope.row.action === 'reject' ? 'Отбивать' : 'Не конвертировать' }}
                </span>
                <el-select v-model="editingConversionRow.action" class="full-width" v-else size="small">
                  <el-option label="Отбивать" value="reject"/>
                  <el-option label="Не конвертировать" value="do_not_convert"/>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column>
              <template #default="scope">
                <ActionsDropdown v-if="scope.$index !== this.editingConversionRowIndex">
                  <ActionButton
                      icon="pencil-alt" @click="editConversionSettingRecord(scope.row, scope.$index)"
                      :disabled="removingConversionSetting"
                  >Изменить
                  </ActionButton>
                  <ActionButton type="remove" @confirm="removeConversionSettingRecord(scope.row)"
                                :loading="removingConversionSetting">
                    Удалить
                  </ActionButton>
                </ActionsDropdown>
                <el-button text
                           v-if="scope.$index === this.editingConversionRowIndex"
                           type="success" @click="saveEditedConversionSettingRecord()"
                           size="small" :loading="removingConversionSetting"
                >Сохранить
                </el-button>

              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <client-rate-details
        :selected-rate="selectedRate"
        :senders="senders"
        :inboxes="inboxes"
        :visible="rateDetailsVisible"
        @close="rateDetailsVisible = false"
    />

    <el-dialog
        v-model="assignRateModalVisible"
        title="Назначение тарифа"
        class="modal-95-70-60"
    >
      <el-form :model="assignRateForm" label-position="top">
        <el-form-item prop="systemId" label="Пользователю">
          <el-select v-model="assignRateForm.systemId" class="full-width">
            <el-option
                v-for="manager in managers.filter(m => !m.system_id.includes('.'))"
                :value="manager.system_id"
                :label="manager.system_id + ' ' + manager.info.lastname + ' ' + manager.info.firstname"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="rateId" label="Тариф">
          <el-select v-model="assignRateForm.rateId" class="full-width" filterable>
            <el-option-group
                v-for="(rates, rateCat) in systemRatesGrouped"
                :label="RATE_TYPES_NAMES[String(rateCat)] || 'N/A'"
            >
              <el-option
                  v-for="rate in rates.sort((r1, r2) => r1.name.localeCompare(r2.name))"
                  :value="rate._id"
                  :label="`${rate.locked ? '[Блок.]' : ''}${rate.name}`"
              >
                <span style="float: left">{{ `${rate.locked ? '[Блок.]' : ''}${rate.name}` }}</span>
                <span style="float: right;color: var(--el-text-color-secondary);font-size: 13px;">
                  {{ !rate.isCommon ? 'Индивид. | ' : '' }}{{ rate.activateAt ? rate.activateAt : '' }}
                </span>
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="success" @click="assignRate" :loading="assigningRate" style="margin-bottom: 20px">Назначить
        </el-button>
      </template>
    </el-dialog>
    <el-button type="success" @click="showAssignRateModal" style="margin-bottom: 20px">Назначить</el-button>

    <el-tabs v-model="activeTab">
      <el-tab-pane
          v-for="systemId in allUserIds"
          :label="systemId"
          :name="systemId"
      >
        <client-rates-table
            :rates="rateData[systemId]"
            :inboxes="inboxes"
            :loading="false"
            :admin-view="true"
            @showRate="showRateDetails"
            @cellClick="handleCellClick"
        >
          <template v-slot:admin-rate-actions>

            <el-table-column min-width="100">
              <template #default="slot">
                <ActionsDropdown v-if="!slot.row.delimiter">
                  <ActionButton icon="pencil-alt" @click="editUserRate(slot.row)">Изменить</ActionButton>
                  <ActionButton type="remove" @confirm="removeUserRate(slot.row, systemId)" :loading="removingUserRate">
                    Удалить
                  </ActionButton>
                </ActionsDropdown>
              </template>
            </el-table-column>
          </template>
        </client-rates-table>
      </el-tab-pane>
    </el-tabs>
  </div>

</template>


<script>

import TableSpinner from "@shared/components/layout/table-spinner.component.vue";
import ClientRatesTable from "../../../shared/components/finance/client-rates-table.component.vue";
import moment from "moment"
import ClientRateDetails from "../../../shared/components/finance/client-rate-details.component.vue";
import RemoveButton from "@shared/components/layout/removeButton.component.vue";

export default {
  name: 'ClientRates',
  components: {RemoveButton, ClientRateDetails, ClientRatesTable, TableSpinner},
  props: ["rateData", "inboxes", "senders", "blockedRoutes", "systemId", "systemRates", "managers"],
  watch: {
    rateData(nv) {
      if (nv && Object.keys(nv).length) this.activeTab = Object.keys(nv)[0]
    },
    "editingRate.namesSettings.allActions": function (v) {
      // console.log("UP")
      // console.log(v)
    }
  },
  methods: {
    callViewUpdate(){
      this.$emit("reloadClientRates", () => this.__set_editing_rate(this.rateData[this.activeTab].find(r => r.user_data._id === this.editingRate.id)))
    },
    handleCellClick(r) {
      if (r.actual_data && ['2', '3', '7', '8'].includes(String(r.actual_data.subtype))) {
        this.visiblePanel = "namesSettings"
        this.editUserRate(r)
      }
    },
    showRateDetails(rateToShow) {
      let _r = rateToShow.actual_data
      _r.sender_names = rateToShow.user_data.sender_names || []
      this.selectedRate = _r
      this.rateDetailsVisible = true
    },
    createNameAction() {
      this.nameActionCreating = true
      this.axios.post("/admin/clients/createRateNameAction", {
        action: {
          actionNameId: this.editingRate.namesSettings.actionNameId,
          actionType: this.editingRate.namesSettings.actionType,
          withdrawNow: this.editingRate.namesSettings.withdrawNow,
          actionDate: this.editingRate.namesSettings.actionDate
        },
        rateId: this.editingRate.id, systemId: this.systemId
      })
          .then(resp => {
            this.nameActionCreating = false
            // this.rateEditModalVisible = false
            // this.$emit("reloadClientRates")
            this.callViewUpdate()
          })
    },
    saveRateBasicSettings() {
      this.savingRate = true
      // console.log(this.editingRate)
      this.axios.post("/admin/clients/updateBasicRateSettings", {
        settings: this.editingRate.basicSettings,
        rateId: this.editingRate.id,
        systemId: this.systemId
      })
          .then(resp => {
            this.savingRate = false
            this.rateEditModalVisible = false
            this.$emit("reloadClientRates")
          })
    },
    editConversionSettingRecord(row, index) {
      this.editingConversionRowIndex = index
      this.editingConversionRow.operatorGroup = row.operatorGroup
      this.editingConversionRow.price = row.price
      this.editingConversionRow.action = row.action
      this.editingConversionRow.sender = row.sender
      this.editingConversionRow._id = row._id
    },
    saveEditedConversionSettingRecord() {
      this.removingConversionSetting = true
      this.axios.post("/admin/clients/saveEditedConversionSettingRecord", {
        settings: this.editingConversionRow,
        rateId: this.editingRate.id,
        systemId: this.systemId
      })
          .then(resp => {
            this.editingConversionRowIndex = -1
            this.removingConversionSetting = false
            this.callViewUpdate()
          })
    },
    addConversionSettingRecord() {
      this.savingRate = true
      this.axios.post("/admin/clients/addConversionSettingRecord", {
        settings: this.editingRate.conversionSettings,
        rateId: this.editingRate.id,
        systemId: this.systemId
      })
          .then(resp => {
            this.savingRate = false
            this.editingRate.conversionSettings.operatorGroup = ""
            this.editingRate.conversionSettings.sender = ""
            this.editingRate.conversionSettings.price = ""
            this.callViewUpdate()
          })
    },
    removeConversionSettingRecord(record) {
      this.removingConversionSetting = true
      this.axios.delete(
          "/admin/clients/removeConversionSettingRecord",
          {params: {rateId: this.editingRate.id, systemId: this.systemId, settingId: record._id}}
      )
          .then(resp => {
            this.removingConversionSetting = false
            this.$emit(
                "reloadClientRates",
                () => this.__set_editing_rate(this.rateData[this.activeTab].find(r => r.user_data._id === this.editingRate.id))
            )
          })
    },
    removeUserRate(userRate, systemId) {
      this.removingUserRate = true
      this.axios.delete("/admin/clients/removeRateFromClient", {params: {rateId: userRate.user_data._id, systemId}})
          .then(resp => {
            this.removingUserRate = false
            this.$emit("reloadClientRates")
          })
    },
    editUserRate(userRate) {
      this.rateEditModalVisible = true
      this.__set_editing_rate(userRate)
    },
    __set_editing_rate(userRate) {
      console.log(userRate)
      this.editingRate.id = userRate.user_data._id
      this.editingRate.name = userRate.actual_data.name
      this.editingRate.rateType = userRate.user_data.r_type
      this.editingRate.rateSubtype = userRate.actual_data.subtype
      this.editingRate.basicSettings.priority = userRate.user_data.priority
      this.editingRate.basicSettings.assignFrom = userRate.user_data.assigned_from ? moment(userRate.user_data.assigned_from) : ""
      this.editingRate.basicSettings.assignTo = userRate.user_data.assigned_to ? moment(userRate.user_data.assigned_to) : ""
      this.editingRate.namesSettings.allNames = (userRate.user_data.sender_names || []).map(usn => {
        return {
          name_id: usn,
          name: (this.senders.find(s => s._id === usn) || {name: "N/A"}).name
        }
      })
      this.editingRate.namesSettings.allActions = (userRate.user_data.sender_names_actions || [])
      this.editingRate.allConversionSettings = (userRate.user_data.all_conversion_settings || []).map(cs => {
        cs.operatorGroupName = (this.$store.getters.operatorsGroupsRus.find(o => o._id === cs.operatorGroup) || {title: ""}).title
        if(!cs.operatorGroupName)
          cs.operatorGroupName = (this.$store.getters.operatorsRus.find(o => o._id === cs.operatorGroup) || {OrgName: ""}).OrgName
        return cs
      })
    },
    showAssignRateModal() {
      this.assignRateForm.systemId = this.systemId
      this.assignRateForm.rateId = ""
      this.assignRateModalVisible = true
    },
    assignRate() {
      this.assigningRate = true
      this.axios.post("/admin/clients/assignRate", {data: this.assignRateForm})
          .then(resp => {
            this.assigningRate = false
            if (resp.data && resp.data.error === "E_DUPLICATE") return this.$gNotify("Такой тариф уже назначен этому пользовтелю", "error")
            this.assignRateModalVisible = false
            this.$emit("reloadClientRates")
          })
    },
    nameActionInFuture(action) {
      return moment(action.date, 'DD.MM.YYYY').startOf("day").isAfter(moment().startOf('day'))
    },
    removePostponedNameAction(action) {
      this.removingPostponedAction = true
      this.axios.delete("/admin/clients/removeRateNameAction", {
        params: {
          actionId: action.id, rateId: this.editingRate.id, systemId: this.systemId
        }
      })
          .then(resp => {
            this.removingPostponedAction = false
            this.$emit("reloadClientRates", () => this.__set_editing_rate(this.rateData[this.activeTab].find(r => r.user_data._id === this.editingRate.id)))
          })
    }
  },
  computed: {
    allUserIds() {
      return Object.keys(this.rateData)
    },
    systemRatesGrouped() {
      return this.systemRates.reduce((acc, r) => {
        if (!acc[r.type]) acc[r.type] = []
        acc[r.type].push(r)
        return acc
      }, {})
    }
  },
  data() {
    return {
      removingUserRate: false,
      removingConversionSetting: false,
      rateEditModalVisible: false,
      editingRate: {
        id: "",
        name: "",
        rateType: 0,
        rateSubtype: 0,
        basicSettings: {
          priority: 0,
          assignFrom: "",
          assignTo: ""
        },
        namesSettings: {
          allNames: [],
          allActions: [],
          actionNameId: "",
          actionType: "now_add",
          withdrawNow: false,
          actionDate: ""
        },
        conversionSettings: {
          sender: "",
          operatorGroup: "",
          price: "",
          action: "",
        },
        allConversionSettings: []
      },
      editingConversionRowIndex: -1,
      editingConversionRow: {
        _id: "",
        sender: "",
        operatorGroup: "",
        price: "",
        action: "",
      },
      visiblePanel: "basicSettings",
      savingRate: false,
      nameActionCreating: false,


      rateDetailsVisible: false,
      selectedRate: {},
      activeTab: "",
      assignRateModalVisible: false,
      assignRateForm: {
        systemId: "",
        rateId: ""
      },
      assigningRate: false,
      removingPostponedAction: false,
      RATE_TYPES_NAMES: {
        "0": "Исходящие",
        "1": "Входящие",
        "14": "Звонки",
        "3": "Оплата банковским переводом",
        "6": "Робокасса",
        "8": "Использование овердрафта",
        "13": "Разовое списание",
      }
    }
  }
}

</script>

<style>

</style>