<template>

  <div>
    <el-dialog v-model="visible"
               :fullscreen="true"
               class="no-body-padding-dialog"
    >
      <template #header>
        <p style="margin: 0px">Управление пользователем {{systemId}}&nbsp;&nbsp;&nbsp;<el-button size="small" type="success" @click="clientLogin">В кабинет</el-button></p>

      </template>

      <TableSpinner :loading="userLoading">
        <el-tabs v-model="visiblePanel" class="primary-heading-tabs">
          <el-tab-pane label="Данные" name="userCard">
            <user-card-config :client-data="clientData" :system-id="systemId" :managers="managers"
                              :finance-legal-entities="financeLegalEntities"
                              @reloadUser="show(systemId)"></user-card-config>
          </el-tab-pane>
          <el-tab-pane label="Тарифы" name="rates">
            <client-rates
                :rate-data="clientRatesData"
                :senders="userSenderNames"
                :system-id="systemId"
                :system-rates="systemRates"
                :managers="userManagers"
                :inboxes="userInboxes"
                :blocked-routes="[]"
                @reloadClientRates="cb => reloadClientRates(cb)"
            />
          </el-tab-pane>
          <el-tab-pane label="Блокировка направлений" name="routeBlocks">
            <client-route-block-rules-view
                :system-id="systemId" :senders="userSenderNames" :rate-data="clientRatesData"
            />
          </el-tab-pane>
          <el-tab-pane label="Имена оптравителя" name="senderNames">
            <client-sender-names-view
                :system-id="systemId" :senders="allClientSenders"
                @refresh="cb => reloadClientSenderNames(cb)"
            />
          </el-tab-pane>
          <el-tab-pane label="Схемы трафика" name="trafficSchemas">
            <client-traffic-schemas
                :system-id="systemId" :managers="userManagers" :senders="userSenderNames"
            />
          </el-tab-pane>
          <el-tab-pane label="Сервис. шаблоны" name="serviceTemplates">
            <service-templates-view
                :system-id="systemId"
                :senders="userSenderNames"
            />
          </el-tab-pane>
          <el-tab-pane label="Подмены отправителя" name="individualSenderSubstitutions">
            <client-individual-substitution-rules
                :system-id="systemId"
                :senders="userSenderNames"
                :managers="userManagers"
            />
          </el-tab-pane>
          <el-tab-pane label="Фин. операции" name="financialOperations">
            <client-financial-operations
                :system-id="systemId"
                :client-data="clientData"
            />
          </el-tab-pane>

        </el-tabs>
      </TableSpinner>

      <template #footer>
      <span class="dialog-footer">
        <el-button @click="this.visible = false">Закрыть</el-button>
      </span>
      </template>
    </el-dialog>
  </div>

</template>


<script>

import TableSpinner from "@shared/components/layout/table-spinner.component.vue";
import UserCardConfig from "./user-card-config.component.vue";
import ClientRates from "./client-rates.component.vue";
import ClientTrafficSchemas from "./client-traffic-schemas.component.vue";
import ServiceTemplatesTable from "@shared/components/lk/service-templates-table.component.vue";
import ClientServiceTemplates from "@/components/clients/client-service-templates.component.vue";
import ClientIndividualSubstitutionRules from "@/components/clients/client-individual-substitution-rules.component.vue";
import ClientFinancialOperations from "@/components/clients/client-financial-operations.view.vue";
import ServiceTemplatesView from "@shared/views/rating/service-templates.view.vue";
import ClientSenderNamesView from "@/components/clients/client-sender-names.component.vue";
import ClientRouteBlockRulesView from "@/components/clients/client-route-block-rules.component.vue";

export default {
  name: 'ClientView',
  components: {
    ClientRouteBlockRulesView,
    ClientSenderNamesView,

    ServiceTemplatesView,
    ClientFinancialOperations,
    ClientIndividualSubstitutionRules,
    ClientServiceTemplates,
    ServiceTemplatesTable, ClientTrafficSchemas, ClientRates, UserCardConfig, TableSpinner},
  props: [],
  watch: {},
  methods: {
    async show(systemId, tab="") {
      console.log(`Show ${systemId}`)
      if(tab) this.visiblePanel=tab
      this.visible = true
      await this.$nextTick()
      this.systemId = systemId
      this.userLoading = true
      await Promise.all([
          this.reloadMainData(),
          this.reloadClientRates(),
          this.reloadClientSenderNames()
      ])
      this.userLoading = false
    },
    reloadMainData(){
      //TODO: Multpiple independent calls splited to function for atomic UI updates on change
      return this.axios.get("/admin/clients/getOneClientView", {params: {systemId: this.systemId}})
          .then(async resp => {
            this.clientData = resp.data.user
            this.systemRates = resp.data.systemRates
            this.managers = resp.data.managers.sort((u1, u2) => u1.system_id.localeCompare(u2.system_id))
            this.userManagers = resp.data.userManagers.sort((u1, u2) => u1.system_id.localeCompare(u2.system_id))
            this.financeLegalEntities = resp.data.financeLegalEntities
            this.userInboxes = resp.data.userInboxes
            // this.userSenderNames = resp.data.userSenderNames.filter(n => !n.forCalls).sort((n1, n2) => n1.name.localeCompare(n2.name))
          })
    },
    reloadClientRates(callback=null){
      this.userLoading = true
      return this.axios.get("/admin/clients/getClientRates", {params: {systemId: this.systemId}})
          .then(async resp => {
            this.clientRatesData = resp.data.allUsersRatesData
            this.userLoading = false
            this.$nextTick().then(() => {
              if(callback) callback()
            })

          })
    },
    reloadClientSenderNames(callback=null){
      this.userLoading = true
      return this.axios.get("/admin/clients/getClientSenderNames", {params: {systemId: this.systemId}})
          .then(async resp => {
            this.userSenderNames = resp.data.userSenderNames.filter(n => !n.forCalls && n.active === 1).sort((n1, n2) => n1.name.localeCompare(n2.name))
            this.allClientSenders = resp.data.userSenderNames
            this.userLoading = false
            this.$nextTick().then(() => {
              if(callback) callback()
            })

          })
    },
    clientLogin() {
      this.axios.get("/admin/clients/surrogateLogin", {params: {systemId: this.systemId}})
          .then(async resp => {
            window.open(`${process.env.VUE_APP_CLIENT_UI_URL}login?token=${resp.data.token}&surrogate=true`, '_blank')
          })
    }
  },
  computed: {

  },
  data() {
    return {
      clientData: {},
      clientBlock: {},
      systemRates: [],
      managers: [],
      financeLegalEntities: [],
      userSenderNames: [],
      userManagers: [],
      userInboxes: [],
      clientRatesData: [],
      allClientSenders: [],
      visible: false,
      userLoading: false,
      systemId: "",
      visiblePanel: "userCard"
    }
  }
}

</script>

<style>

</style>