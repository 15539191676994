<template>
  <div>

    <client-view
        ref="clientView"
    />
    <el-dialog v-model="topupsVisible" title="Детализация пополнений" class="modal-95-70-60">
      <el-table :data="topupsRows">
        <el-table-column label="Месяц" prop="month" width="120"/>
        <el-table-column label="Способ пополнения" prop="system" min-width="180"/>
        <el-table-column label="Дата" prop="date" width="120"/>
        <el-table-column label="Сумма" prop="sum" width="120"/>
      </el-table>
    </el-dialog>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs v-model="activeTab" class="primary-heading-tabs">
          <el-tab-pane name="report" label="Отчет">
            <el-row>
              <el-col :xs="24" :md="12">
                <h4 class="report-parameters-title">Параметры отчета</h4>
                <TimeSearch
                    ref="timeSearch"
                    :month="true"
                    :full-width-select="true"
                    :show-last-month="true"
                />
                <!--                <el-divider class="blue-divider"/>-->
                <span @click="additionaFilterVisible = !additionaFilterVisible"
                      style="color: var(--el-color-primary); cursor: pointer;">Доп. фильтры<br/><br/></span>
                <el-form label-position="top" v-show="additionaFilterVisible">
                  <el-form-item label="Тип контрагента">
                    <el-select v-model="filter.agentType" class="full-width">
                      <el-option value="" label="Все"></el-option>
                      <el-option value="physic" label="Физ. лица"></el-option>
                      <el-option value="individual" label="Юр. лица"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Активность">
                    <el-select v-model="filter.agentActivity" class="full-width">
                      <el-option value="" label="Все"></el-option>
                      <el-option value="active" label="Активные"></el-option>
                      <el-option value="inactive" label="Не активные"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Свободный поиск">
                    <el-input v-model="filter.freeSearchToken" clearable/>
                  </el-form-item>
                </el-form>
                <el-button type="success" plain @click="getReport" :loading="reportLoading" style="margin-top: 30px">
                  Применить
                </el-button>
              </el-col>
            </el-row>

            <TableSpinner :loading="reportLoading" style="margin-top: 50px">
              <el-button text class="text-button" @click="exportReportData"
                         style="padding: 0px; min-height: auto;height: auto !important">
                <font-awesome-icon icon="external-link-alt"/>&nbsp;Экспортировать
              </el-button>
              <el-table
                  :data="visiblePortion"
                  style="width: 100%; margin-bottom: 50px; margin-top: 50px"
                  :summary-method="getSummaries"
                  :header-cell-style="headerCellStyle"
                  show-summary
              >
                <el-table-column :formatter="row => formatDate(row.cat)" label="Дата" width="100"/>
                <el-table-column label="Контрагент" sortable min-width="130">
                  <template #default="scope">
                    <el-button text class="table-text-button" @click="showUserProfile(scope.row)">
                      {{ scope.row.userDataStr }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column label="Менеджер" prop="managerDataStr" sortable min-width="130"/>
<!--                <el-table-column label="ЭДО" prop="sendEdo" soratble width="60"/>-->
                <el-table-column label="Пополнения">
                  <el-table-column label="Реальные" min-width="110">
                    <template #default="scope">
                      <el-button text class="table-text-button" @click="showTopups(scope.row, 'real')">
                        {{ scope.row.topups + (scope.row.negActValue ? ` (${Number(scope.row.negActValue)})` : "") }}
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column label="Тестовые" prop="testTopups" min-width="110">
                    <template #default="scope">
                      <el-button text class="table-text-button" @click="showTopups(scope.row, 'test')">
                        {{ scope.row.testTopups }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="Расходы">
                  <el-table-column label="Без РС" prop="spendingNoRs" soratble min-width="110"/>
                  <el-table-column label="Ручные списания" prop="spendingRs" soratble min-width="110"/>
                </el-table-column>
                <el-table-column label="Акт (УПД)">
                  <el-table-column label="Сумма" prop="actValue" soratble min-width="120"/>
                  <el-table-column label="Сообщения" prop="nonLicenseValue" soratble min-width="120"/>
                  <el-table-column label="Лицензия" prop="licenseValue" soratble min-width="120"/>
                  <el-table-column label="Номер" prop="actNumber" soratble min-width="80"/>
                </el-table-column>
                <el-table-column label="Баланс">
                  <el-table-column label="Система" prop="systemBalance" soratble min-width="120"/>
                  <el-table-column label="1С" prop="oneCBalance" soratble min-width="100"/>
                </el-table-column>
                <el-table-column label="Овердрафт" prop="overdraft" soratble min-width="120"/>
                <el-table-column label="Поставщик" prop="contractor" soratble min-width="120"/>
                <el-table-column label="Код агента" prop="agentCode" soratble min-width="100"/>
                <el-table-column label="Вид оплаты" soratble min-width="100">
                  <template #default="scope">
                    <el-tag style="margin-top: 5px" :type="scope.row.contractType === '0' ? 'success' : 'danger'">
                      {{ scope.row.contractType === '0' ? 'Аванс.' : 'Кредит.' }}
                    </el-tag>
                  </template>
                </el-table-column>
              </el-table>
              <div class="solo-pagination-container">
                <el-pagination
                    background
                    class="wide-page-size"
                    layout="sizes, prev, pager, next"
                    :total="filteredRows.length"
                    :pager-count="3"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :hide-on-single-page="true"
                    @size-change="handlePageSizeChange"
                    @current-change="handleCurrentPageChange"
                >
                </el-pagination>
              </div>
            </TableSpinner>
          </el-tab-pane>
          <el-tab-pane name="balances" label="Авансы/Овердрафты">

            <TableSpinner :loading="balancesLoading" style="margin-top: 50px" v-show="balancesAndOverdraftReportReady">
<!--              <el-button text class="text-button" @click="exportReportData"-->
<!--                         style="padding: 0px; min-height: auto;height: auto !important">-->
<!--                <font-awesome-icon icon="external-link-alt"/>&nbsp;Экспортировать-->
<!--              </el-button>-->
              <el-row style="margin-bottom: 40px" v-show="balancesAndOverdraftReportReady && balancesAndOverdraftRows.length">
                <el-col :span="24">
                  <div style="width: 100%; overflow-x:scroll">
                    <div style="min-width: 1200px; height:500px">
                      <canvas id="acquisitions"></canvas>
                    </div>
                  </div>
                </el-col>
              </el-row>

              <el-table
                  :data="balancesAndOverdraftRows"
                  style="width: 100%; margin-bottom: 50px; margin-top: 50px"
                  :summary-method="getBalancesAndOverdraftsSummaries"
                  show-summary
              >
                <el-table-column prop="month" label="Месяц" width="150"/>
                <el-table-column :formatter="r => formatNumber(r.pos, '.', 2)" label="+ балансы" min-width="130" />
                <el-table-column :formatter="r => formatNumber(r.neg, '.', 2)" label="- балансы" min-width="130" />
                <el-table-column :formatter="r => formatNumber(r.overdraft, '.', 2)" label="Овердрафты" min-width="130" />
              </el-table>

            </TableSpinner>
            <el-button type="success" plain @click="getBalances" :loading="balancesLoading" style="margin-top: 30px">
              Показать
            </el-button>
          </el-tab-pane>
          <el-tab-pane name="oneCBalances" label="Балансы 1С">
            <el-form label-position="top">
              <el-form-item label="Месяц">
                <el-select v-model="oneCUploadForm.month" class="full-width">
                  <el-option
                      v-for="month in staticMonths"
                      :key="month.value"
                      :label="month.label"
                      :value="month.value"
                  />

                </el-select>
              </el-form-item>
              <el-form-item label="Файл">
                <input id="upload" type=file name="files[]">
              </el-form-item>
              <el-button type="success" plain @click="saveOneCBalances" :loading="balancesSaving"
                         :disabled="!oneCUploadForm.balances.length" style="margin-top: 30px">Загрузить
              </el-button>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>


import TimeSearch from "@shared/components/reports/time-search.component"
import {read as readXlsx, utils} from "xlsx"
import iconv from "iconv-lite"

import moment from "moment"
import ClientView from "../../components/clients/client-view.component.vue";
import Chart from "chart.js/auto";

const paymentSystems = [
  'Безналичный банковский перевод',
  'Assist',
  'Qiwi',
  'ROBOXchange',
  'WebMoney',
  'Вывод денег через банк',
  'Банковские платежи',
  'Переводы средств для тестирования',
  'Внутренние переводы',
  'ЮMoney'
]

export default {
  name: "FinancialOperations",
  title: "Остатки на счетах",
  components: {
    ClientView,
    TimeSearch
  },
  mounted() {
    let now = moment()
    let months = []
    for (let i = 1; i <= 24; i++) {
      months.push({label: now.locale("ru").format("YYYY MMMM"), value: now.startOf("month").format("DD.MM.YYYY")})
      now = moment().subtract(i, "months")
    }
    this.staticMonths = months
    this.oneCUploadForm.month = months[0]
    document.getElementById('upload').addEventListener('change', evt => this.excelToJson(evt.target.files[0]), false);
  },
  methods: {
    excelToJson(file) {
      this.oneCUploadForm.balances = []
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = e.target.result;
        let workbook = readXlsx(data, {
          type: 'binary'
        });
        workbook.SheetNames.forEach(sheetName => {
          this.oneCUploadForm.balances = utils.sheet_to_csv(workbook.Sheets[sheetName], {FS: ";"}).split("\n").map(r => r.split(";").map(v => v.replace(/"/g, "")))
          console.log(this.oneCUploadForm.balances)
        })
      }

      reader.onerror = function (ex) {
        console.log(ex);
      };
      reader.readAsBinaryString(file);
    },
    saveOneCBalances() {
      this.balancesSaving = true
      this.axios.post("/admin/finance/saveOneCBalances", this.oneCUploadForm)
          .then(resp => {
            this.$gNotify("Балансы загружены", "success")
            this.balancesSaving = false
          })

    },
    getReport() {
      this.reportLoading = true
      let dateFilter = this.$refs.timeSearch.getDateTimeFilter()
      this.reportMonth = dateFilter.from.format("DD.MM.YYYY")
      this.axios.get("/admin/finance/balancesReport", {
        params: {
          from: dateFilter.from.format("DD.MM.YYYY"),
          to: dateFilter.to.format("DD.MM.YYYY")
        }
      })
          .then(resp => {
            this.reportRows = resp.data.reportRows || []
            this.reportLoading = false
          })
          .catch(() => {})
    },
    exportReportData(){
      let element = document.createElement('a')
      let text = "Дата;Контрагент;Менеджер;Сумма пополнения;Тестовые пополнения;Сумма расходов (Без РС);Сумма расходов (Ручные списания);Акт (УПД);Стоимость сообщений;Стоимость лицензии;№ Акта (УПД);Баланс в системе;Баланс в 1С;Овердрафт;Поставщик;Код агента;Вид оплаты;\n"
      for(let row of this.filteredRows) {
        text += `${this.formatDate(row.cat)};`
        text += `${row.userDataStr};`
        text += `${row.managerDataStr};`
        // text += `${row.sendEdo};`
        text += `${row.topups + (row.negActValue ? ` (${Number(row.negActValue)})` : "")};`
        text += `${row.testTopups};`
        text += `${row.spendingNoRs};`
        text += `${row.spendingRs};`
        text += `${row.actValue};`
        text += `${row.nonLicenseValue};`
        text += `${row.licenseValue};`
        text += `${row.actNumber};`
        text += `${row.systemBalance};`
        text += `${row.oneCBalance};`
        text += `${row.overdraft};`
        text += `${row.contractor};`
        text += `${row.agentCode};`
        text += `${row.contractType === '0' ? 'Аванс.' : 'Кредит.' };\n`
      }
      let csvContent = iconv.encode(text, 'win1251').toString("base64");
      element.setAttribute('href', 'data:application/octet-stream;base64,' + encodeURIComponent(csvContent))
      element.setAttribute('download', `Остатки на счетах за ${moment(this.reportMonth, "DD.MM.YYYY").format("MMMM YYYY")}.csv`)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element);
    },
    getSummaries() {
      let finals = this.filteredRows.reduce((acc, r) => {
        acc.sumTopup += Number(r.topups) || 0
        acc.sumTestTopup += Number(r.testTopups) || 0
        acc.sumSpendingNoRs += Number(r.spendingNoRs) || 0
        acc.sumSpendingRs += Number(r.spendingRs) || 0
        acc.sumAct += Number(r.actValue) || 0
        acc.sumLicense += Number(r.licenseValue) || 0
        acc.sumNonLicense += Number(r.nonLicenseValue) || 0
        acc.sumBalance += Number(r.systemBalance) || 0
        acc.sumOneCBalance += Number(r.oneCBalance) || 0
        acc.sumOverdraft += Number(r.overdraft) || 0
        return acc
      }, {
        sumTopup: 0,
        sumTestTopup: 0,
        sumOverdraft: 0,
        sumSpendingNoRs: 0,
        sumSpendingRs: 0,
        sumAct: 0,
        sumLicense: 0,
        sumNonLicense: 0,
        sumBalance: 0,
        sumOneCBalance: 0
      })
      return [
        "Итого", "", "",  finals.sumTopup.toFixed(2), finals.sumTestTopup.toFixed(2), finals.sumSpendingNoRs.toFixed(2),
        finals.sumSpendingRs.toFixed(2), finals.sumAct.toFixed(2), finals.sumNonLicense.toFixed(2), finals.sumLicense.toFixed(2), "",
        finals.sumBalance.toFixed(2), finals.sumOneCBalance.toFixed(2), finals.sumOverdraft.toFixed(2), ""
      ]
    },
    headerCellStyle({row, column, rowIndex, columnIndex}) {
      if (rowIndex === 0 && columnIndex === 3)
        return {borderRight: "2px solid black !important", borderLeft: "2px solid black !important"}
      if (rowIndex === 0 && [4,5,6].indexOf(columnIndex) > -1)
        return {borderRight: "2px solid black !important"}

      if (rowIndex === 1 && columnIndex === 0)
        return {
          borderLeft: "2px solid black !important",
          borderRight: "2px solid black !important",
          "paddingLeft": "10px !important"
        }
      if (rowIndex === 1 && [1, 2, 3, 4, 5, 6, 7].indexOf(columnIndex) > -1)
        return {borderRight: "2px solid black !important"}
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
    },
    showTopups(row, type) {
      this.axios.get("/admin/finance/getBalancesTopupsForUser", {params: {month: this.reportMonth, userId: row.systemId}})
          .then(resp => {
            this.topupsRows = resp.data.map(r => {
              if (type === "test" && r.payment_system === 0 && r.type === 0) return
              if (type === "real" && (r.payment_system !== 0 || r.type !== 0)) return
              return {
                  month: moment(r.createdAt).format("MMMM"),
                  system: paymentSystems[r.payment_system] || "N/A",
                  date: moment(r.createdAt).format("DD.MM.YYYY"),
                  sum: parseFloat(r.summ).toFixed(2) + ' руб.'
                }
            }).filter(v => v)
            console.log("Done")
            this.topupsVisible = true
          })

    },
    async showUserProfile(row) {
      await this.$refs.clientView.show(row.systemId)
    },

    getBalances() {
      this.balancesLoading = true
      this.axios.get("/admin/finance/balanceAndOverdraftReport")
          .then(resp => {
            this.balancesAndOverdraftRows = resp.data || []
            this.balancesLoading = false
            this.balancesAndOverdraftReportReady = true
            this.drawChart()
          })
          .catch(() => {})
    },
    drawChart(){
      let datasets = [

        {
          label: '+ балансы',
          data: this.balancesAndOverdraftRows.map(d => d.pos),
          borderColor: "#00AA55",
          backgroundColor: "#00AA55",
          // stack: 'stack_0'
        },
        {
          label: '- балансы',
          data: this.balancesAndOverdraftRows.map(d => d.neg),
          borderColor: "#FF0000",
          backgroundColor: "#FF0000",
          // stack: 'stack_1'
        },
        {
          label: 'Овердрафты',
          data: this.balancesAndOverdraftRows.map(d => d.overdraft),
          borderColor: "#59a7d7",
          backgroundColor: "#59a7d7",
          // stack: 'stack_2'
        },


      ]
      let chartData = {
        labels: this.balancesAndOverdraftRows.map(d => d.month),
        datasets
      }

      if (this.chartInstance)
        this.chartInstance.destroy()

      this.chartInstance = new Chart(
          document.getElementById('acquisitions'),
          {
            type: 'line',
            options: {
              // scales: {
              //   x: {stacked: true, grid: {display: false}},
              //   y: {
              //     stacked: true, type: 'linear', grid: {display: false},
              //     ticks: {
              //       callback: function (value) {
              //         if (String(value).includes(".")) return ""
              //         return value
              //       }
              //     }
              //   },
              //
              // },
              plugins: {
                legend: {align: "start"},
              },
              animation: false,
              maintainAspectRatio: false
            },
            data: chartData,
            plugins: [{
              beforeInit: function (chart, options) {
                const originalFit = chart.legend.fit;
                chart.legend.fit = function fit() {
                  originalFit.bind(chart.legend)();
                  this.height += 30;
                }
              },
              afterRender: () => {
                // this.drawing = false
              }
            }]
          }
      );
    },
    getBalancesAndOverdraftsSummaries(){
      let finals = this.balancesAndOverdraftRows.reduce((acc, r) => {
        acc.pos += Number(r.pos) || 0
        acc.neg += Number(r.neg) || 0
        acc.overdraft += Number(r.overdraft) || 0
        return acc
      }, {pos: 0, neg: 0, overdraft: 0})
      return [
        "Итого",
        finals.pos.toFixed(2), finals.neg.toFixed(2), finals.overdraft.toFixed(2)
      ]
    }
  },
  computed: {
    filteredRows() {
      return this.reportRows.filter(r => {
        if (this.filter.agentType && r.agentType !== this.filter.agentType) return false
        if (this.filter.agentActivity) {
          if (this.filter.agentActivity === 'active' && (
              Number(r.topups) + Number(r.testTopups) === 0 && Number(r.spendingTotal) === 0 && Number(r.spendingRs) === 0
          )) return false
          if (this.filter.agentActivity === 'inactive' && (
              Number(r.topups) + Number(r.testTopups) > 0 || Number(r.spendingTotal) > 0  || Number(r.spendingRs) > 0
          )) return false
        }
        if(this.filter.freeSearchToken){
          if(
              !(new RegExp(String(this.filter.freeSearchToken), "gi")).test(r.userDataStr) &&
              this.filter.freeSearchToken.trim() !== r.agentCode
          ) return false
        }
        return true
      })
    },
    visiblePortion() {
      return this.filteredRows.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    }
  },
  data() {
    return {
      reportLoading: false,
      reportRows: [],
      currentPage: 1,
      pageSize: 20,
      additionaFilterVisible: false,
      filter: {
        agentType: "",
        agentActivity: "",
        freeSearchToken: ""
      },
      reportMonth: "",
      activeTab: "report",
      staticMonths: [],
      oneCUploadForm: {
        month: "",
        balances: []
      },
      uploadedFiles: [],
      uploadedFile: {name: ""},
      balancesSaving: false,

      topupsVisible: false,
      topupsRows: [],


      chartInstance: null,
      balancesAndOverdraftReportReady : false,
      balancesLoading: false,
      balancesAndOverdraftRows: []
    }
  }
}

</script>